@charset "UTF-8";
@font-face {
  font-family: "EudoxusSans";
  src: url("EudoxusSans-Regular.ttf");
  src: local("Eudoxus Sans"), url("EudoxusSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "EudoxusSans";
  src: url("EudoxusSans-Medium.ttf");
  src: local("Eudoxus Sans"), url("EudoxusSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "EudoxusSans";
  src: url("EudoxusSans-Bold.ttf");
  src: local("Eudoxus Sans"), url("EudoxusSans-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "EudoxusSans";
  src: url("EudoxusSans-ExtraBold.ttf");
  src: local("Eudoxus"), url("EudoxusSans-ExtraBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
:root {
  --bs-red: #cc212d;
  --bs-yellow: #d98f00;
  --bs-green: #056c47;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-gray: #818181;
  --bs-blue: #319cff;
  --bs-red: #cc212d;
  --bs-yellow: #d98f00;
  --bs-green: #056c47;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-gray: #818181;
  --bs-blue: #319cff;
  --bs-primary-200: #056c47;
  --bs-primary: #0b8659;
  --bs-primary-80: #3b9e7a;
  --bs-primary-60: #6cb69b;
  --bs-primary-40: #9bcfbd;
  --bs-primary-30: #b6dbce;
  --bs-primary-10: #e8f4ef;
  --bs-gray-100: #737377;
  --bs-gray-80: #abadae;
  --bs-gray-40: #d4d4d4;
  --bs-gray-20: #e8e8e8;
  --bs-gray-10: #f5f5f5;
  --bs-red-10: #fae9ea;
  --bs-red-40: #eba6ab;
  --bs-yellow-10: #fcf4e6;
  --bs-yellow-40: #d98f00;
  --bs-red-rgb: 204, 33, 45;
  --bs-yellow-rgb: 217, 143, 0;
  --bs-green-rgb: 5, 108, 71;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-gray-rgb: 129, 129, 129;
  --bs-blue-rgb: 49, 156, 255;
  --bs-primary-200-rgb: 5, 108, 71;
  --bs-primary-rgb: 11, 134, 89;
  --bs-primary-80-rgb: 59, 158, 122;
  --bs-primary-60-rgb: 108, 182, 155;
  --bs-primary-40-rgb: 155, 207, 189;
  --bs-primary-30-rgb: 182, 219, 206;
  --bs-primary-10-rgb: 232, 244, 239;
  --bs-gray-100-rgb: 115, 115, 119;
  --bs-gray-80-rgb: 171, 173, 174;
  --bs-gray-40-rgb: 212, 212, 212;
  --bs-gray-20-rgb: 232, 232, 232;
  --bs-gray-10-rgb: 245, 245, 245;
  --bs-red-10-rgb: 250, 233, 234;
  --bs-red-40-rgb: 235, 166, 171;
  --bs-yellow-10-rgb: 252, 244, 230;
  --bs-yellow-40-rgb: 217, 143, 0;
  --bs-font-sans-serif: "EudoxusSans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.4;
  --bs-body-color: #000;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  color: #e8e8e8;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small, .small {
  font-size: 0.875rem;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75rem;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0b8659;
  text-decoration: none;
}
a:hover {
  color: #096b47;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875rem;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875rem;
  color: #cc212d;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #818181;
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0rem;
  padding-bottom: 0rem;
  color: #818181;
  text-align: left;
}

th {
  font-weight: 400;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.caption {
  font-size: 0.75rem;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #818181;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-fit {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1.5rem);
}
.grid .g-col-1 {
  grid-column: auto/span 1;
}
.grid .g-col-2 {
  grid-column: auto/span 2;
}
.grid .g-col-3 {
  grid-column: auto/span 3;
}
.grid .g-col-4 {
  grid-column: auto/span 4;
}
.grid .g-col-5 {
  grid-column: auto/span 5;
}
.grid .g-col-6 {
  grid-column: auto/span 6;
}
.grid .g-col-7 {
  grid-column: auto/span 7;
}
.grid .g-col-8 {
  grid-column: auto/span 8;
}
.grid .g-col-9 {
  grid-column: auto/span 9;
}
.grid .g-col-10 {
  grid-column: auto/span 10;
}
.grid .g-col-11 {
  grid-column: auto/span 11;
}
.grid .g-col-12 {
  grid-column: auto/span 12;
}
.grid .g-start-1 {
  grid-column-start: 1;
}
.grid .g-start-2 {
  grid-column-start: 2;
}
.grid .g-start-3 {
  grid-column-start: 3;
}
.grid .g-start-4 {
  grid-column-start: 4;
}
.grid .g-start-5 {
  grid-column-start: 5;
}
.grid .g-start-6 {
  grid-column-start: 6;
}
.grid .g-start-7 {
  grid-column-start: 7;
}
.grid .g-start-8 {
  grid-column-start: 8;
}
.grid .g-start-9 {
  grid-column-start: 9;
}
.grid .g-start-10 {
  grid-column-start: 10;
}
.grid .g-start-11 {
  grid-column-start: 11;
}
@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-sm-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-sm-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-sm-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-sm-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-sm-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-sm-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-sm-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-sm-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-sm-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-sm-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-sm-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }
  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }
  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }
  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }
  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }
  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }
  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }
  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }
  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }
  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }
  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 768px) {
  .grid .g-col-md-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-md-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-md-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-md-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-md-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-md-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-md-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-md-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-md-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-md-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-md-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-md-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-md-1 {
    grid-column-start: 1;
  }
  .grid .g-start-md-2 {
    grid-column-start: 2;
  }
  .grid .g-start-md-3 {
    grid-column-start: 3;
  }
  .grid .g-start-md-4 {
    grid-column-start: 4;
  }
  .grid .g-start-md-5 {
    grid-column-start: 5;
  }
  .grid .g-start-md-6 {
    grid-column-start: 6;
  }
  .grid .g-start-md-7 {
    grid-column-start: 7;
  }
  .grid .g-start-md-8 {
    grid-column-start: 8;
  }
  .grid .g-start-md-9 {
    grid-column-start: 9;
  }
  .grid .g-start-md-10 {
    grid-column-start: 10;
  }
  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-lg-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-lg-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-lg-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-lg-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-lg-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-lg-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-lg-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-lg-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-lg-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-lg-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-lg-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }
  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }
  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }
  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }
  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }
  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }
  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }
  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }
  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }
  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }
  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1400px) {
  .grid .g-col-xxl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xxl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xxl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xxl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xxl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xxl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xxl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xxl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xxl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xxl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xxl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xxl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.25rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.25rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 2.5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 2.75rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 2.75rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 3rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 3rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 3.25rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 3.25rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 3.5rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 3.5rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 3.75rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 3.75rem;
}

.g-16,
.gx-16 {
  --bs-gutter-x: 4rem;
}

.g-16,
.gy-16 {
  --bs-gutter-y: 4rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-11,
.gx-sm-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-sm-11,
.gy-sm-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-sm-12,
.gx-sm-12 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-12,
.gy-sm-12 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-13,
.gx-sm-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-sm-13,
.gy-sm-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-sm-14,
.gx-sm-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-14,
.gy-sm-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-15,
.gx-sm-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-sm-15,
.gy-sm-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-sm-16,
.gx-sm-16 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-16,
.gy-sm-16 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 2rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 2rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-11,
.gx-md-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-md-11,
.gy-md-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-md-12,
.gx-md-12 {
    --bs-gutter-x: 3rem;
  }

  .g-md-12,
.gy-md-12 {
    --bs-gutter-y: 3rem;
  }

  .g-md-13,
.gx-md-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-md-13,
.gy-md-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-md-14,
.gx-md-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-14,
.gy-md-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-15,
.gx-md-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-md-15,
.gy-md-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-md-16,
.gx-md-16 {
    --bs-gutter-x: 4rem;
  }

  .g-md-16,
.gy-md-16 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-11,
.gx-lg-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-lg-11,
.gy-lg-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-lg-12,
.gx-lg-12 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-12,
.gy-lg-12 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-13,
.gx-lg-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-lg-13,
.gy-lg-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-lg-14,
.gx-lg-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-14,
.gy-lg-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-15,
.gx-lg-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-lg-15,
.gy-lg-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-lg-16,
.gx-lg-16 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-16,
.gy-lg-16 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-11,
.gx-xl-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-xl-11,
.gy-xl-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-xl-12,
.gx-xl-12 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-12,
.gy-xl-12 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-13,
.gx-xl-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-xl-13,
.gy-xl-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-xl-14,
.gx-xl-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-14,
.gy-xl-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-15,
.gx-xl-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-xl-15,
.gy-xl-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-xl-16,
.gx-xl-16 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-16,
.gy-xl-16 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-11,
.gx-xxl-11 {
    --bs-gutter-x: 2.75rem;
  }

  .g-xxl-11,
.gy-xxl-11 {
    --bs-gutter-y: 2.75rem;
  }

  .g-xxl-12,
.gx-xxl-12 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-12,
.gy-xxl-12 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-13,
.gx-xxl-13 {
    --bs-gutter-x: 3.25rem;
  }

  .g-xxl-13,
.gy-xxl-13 {
    --bs-gutter-y: 3.25rem;
  }

  .g-xxl-14,
.gx-xxl-14 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-14,
.gy-xxl-14 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-15,
.gx-xxl-15 {
    --bs-gutter-x: 3.75rem;
  }

  .g-xxl-15,
.gy-xxl-15 {
    --bs-gutter-y: 3.75rem;
  }

  .g-xxl-16,
.gx-xxl-16 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-16,
.gy-xxl-16 {
    --bs-gutter-y: 4rem;
  }
}
.table {
  --bs-table-bg: #fff;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #000;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #000;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #000;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 0;
  color: #000;
  font-size: 0.875rem;
  vertical-align: top;
  border-color: #e8e8e8;
}
.table > :not(caption) > * > * {
  padding: 0;
  background-color: var(--bs-table-bg);
  border-top-width: 1px;
}
.table > tbody {
  vertical-align: inherit;
}
.table > tbody small, .table > tbody .small {
  display: block;
  line-height: 1.25;
}
.table > thead {
  vertical-align: bottom;
  color: #818181;
}
.table > thead > * > * {
  border-top: none;
}
.table > :not(:first-child) {
  border-top: 1px solid #e8e8e8;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cee7de;
  --bs-table-striped-bg: #c4dbd3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9d0c8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd6cd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b9d0c8;
}

.table-secondary {
  --bs-table-bg: #f7e9cc;
  --bs-table-striped-bg: #ebddc2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ded2b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4d8bd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #ded2b8;
}

.table-success {
  --bs-table-bg: #cde2da;
  --bs-table-striped-bg: #c3d7cf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9cbc4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bed1ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b9cbc4;
}

.table-info {
  --bs-table-bg: #d6ebff;
  --bs-table-striped-bg: #cbdff2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d4e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6d9ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1d4e6;
}

.table-warning {
  --bs-table-bg: #f7e9cc;
  --bs-table-striped-bg: #ebddc2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ded2b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4d8bd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #ded2b8;
}

.table-danger {
  --bs-table-bg: #f5d3d5;
  --bs-table-striped-bg: #e9c8ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ddbec0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3c3c5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #ddbec0;
}

.table-light {
  --bs-table-bg: #f5f5f5;
  --bs-table-striped-bg: #e9e9e9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dddddd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3e3e3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dddddd;
}

.table-dark {
  --bs-table-bg: #000;
  --bs-table-striped-bg: #0d0d0d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1a1a1a;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #131313;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #1a1a1a;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  color: #818181;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #818181;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #d4d4d4;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  min-height: 2.875rem;
  border-radius: 0.125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #000;
  background-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(11, 134, 89, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.4em;
}
.form-control::-moz-placeholder {
  color: #abadae;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #abadae;
  opacity: 1;
}
.form-control::placeholder {
  color: #abadae;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f5f5f5;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
  color: #000;
  background-color: #e8e8e8;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: gainsboro;
}
.form-control::-webkit-file-upload-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
  color: #000;
  background-color: #e8e8e8;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: gainsboro;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  line-height: 1.4;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.4em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.4em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.75rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.4em + 1.5rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.4em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.4em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.75rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.4em;
  border-radius: 0.125rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.4em;
  border-radius: 0.125rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23abadae' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #d4d4d4;
  border-radius: 0.125rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0rem rgba(11, 134, 89, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e8e8e8;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.75rem;
}

.form-check {
  display: block;
  min-height: 1.4rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(11, 134, 89, 0.25);
}
.form-check-input:checked {
  background-color: #0b8659;
  border-color: #0b8659;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0b8659;
  border-color: #0b8659;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill=''/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(11, 134, 89, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(11, 134, 89, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0b8659;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6dbcd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e8e8e8;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0b8659;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6dbcd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e8e8e8;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #d4d4d4;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #d4d4d4;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #e8e8e8;
  border: 1px solid #d4d4d4;
  border-radius: 0.125rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.75rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #056c47;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #056c47;
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #056c47;
  padding-right: calc(1.4em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23056c47' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35em + 0.375rem) center;
  background-size: calc(0.7em + 0.75rem) calc(0.7em + 0.75rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #056c47;
  box-shadow: 0 0 0 0rem rgba(5, 108, 71, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.4em + 1.5rem);
  background-position: top calc(0.35em + 0.375rem) right calc(0.35em + 0.375rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #056c47;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23abadae' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23056c47' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.7em + 0.75rem) calc(0.7em + 0.75rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #056c47;
  box-shadow: 0 0 0 0rem rgba(5, 108, 71, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #056c47;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #056c47;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0rem rgba(5, 108, 71, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #056c47;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #cc212d;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #cc212d;
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #cc212d;
  padding-right: calc(1.4em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23cc212d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cc212d' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35em + 0.375rem) center;
  background-size: calc(0.7em + 0.75rem) calc(0.7em + 0.75rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #cc212d;
  box-shadow: 0 0 0 0rem rgba(204, 33, 45, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.4em + 1.5rem);
  background-position: top calc(0.35em + 0.375rem) right calc(0.35em + 0.375rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #cc212d;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23abadae' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23cc212d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cc212d' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.7em + 0.75rem) calc(0.7em + 0.75rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #cc212d;
  box-shadow: 0 0 0 0rem rgba(204, 33, 45, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #cc212d;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #cc212d;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0rem rgba(204, 33, 45, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #cc212d;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #000;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background-color: transparent;
  font-weight: 600;
  line-height: 1.4;
  white-space: nowrap;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 0 1rem;
  font-size: 0.875rem;
  border-radius: 0.125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn > svg {
  transition: ease-in-out 0.15s transform;
  transform: translateX(0);
}
.btn:hover.-trans-icon > svg {
  transition: ease-in-out 0.15s transform;
  transform: translateX(0.5rem);
}
.btn.-no-hover {
  background-image: none;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(11, 134, 89, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  box-shadow: none;
}
.btn:disabled svg path, .btn.disabled svg path, fieldset:disabled .btn svg path {
  fill: #818181 !important;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropdown-divider {
  height: 0;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.875rem 1rem;
  clear: both;
  font-size: 0.875rem;
  font-weight: 400;
  color: #000;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: black;
  background-color: #e8e8e8;
}
.dropdown-item.active, .dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color: #e8e8e8;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #d4d4d4;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #abadae;
  white-space: nowrap;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  color: #000;
  display: block;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #096b47;
}
.nav-link.disabled {
  color: #abadae;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  gap: 2.5rem;
  border-bottom: 0.0625rem solid #e8e8e8;
}
.nav-tabs .nav-link {
  cursor: pointer;
  background: none;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  border-bottom: 2px solid transparent;
  margin-bottom: -0.0625rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #abadae;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #0b8659;
  background-color: #fff;
  font-weight: 600;
  border-color: #0b8659;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.125rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.125rem - 1px);
  border-top-right-radius: calc(0.125rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.125rem - 1px);
  border-bottom-left-radius: calc(0.125rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.125rem - 1px) calc(0.125rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.125rem - 1px) calc(0.125rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.125rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.125rem - 1px);
  border-top-right-radius: calc(0.125rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.125rem - 1px);
  border-bottom-left-radius: calc(0.125rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e8e8e8;
  border-radius: 0.125rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0b8659;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 0.125rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #abadae;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.125rem - 1px);
  border-top-right-radius: calc(0.125rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e8e8e8;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.4;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e8e8e8;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 480px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #0b8659;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #0b8659;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #0b8659;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #0b8659;
}

.tooltip-inner {
  max-width: 274px;
  padding: 0.5rem 0.5rem;
  color: #fff;
  text-align: left;
  line-height: 1.5;
  font-size: 0.875rem;
  background-color: #0b8659;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.75rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
          animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-red {
  color: #cc212d;
}
.link-red:hover, .link-red:focus {
  color: #a31a24;
}

.link-yellow {
  color: #d98f00;
}
.link-yellow:hover, .link-yellow:focus {
  color: #e1a533;
}

.link-green {
  color: #056c47;
}
.link-green:hover, .link-green:focus {
  color: #045639;
}

.link-white {
  color: #fff;
}
.link-white:hover, .link-white:focus {
  color: white;
}

.link-black {
  color: #000;
}
.link-black:hover, .link-black:focus {
  color: black;
}

.link-gray {
  color: #818181;
}
.link-gray:hover, .link-gray:focus {
  color: #9a9a9a;
}

.link-blue {
  color: #319cff;
}
.link-blue:hover, .link-blue:focus {
  color: #5ab0ff;
}

.link-primary-200 {
  color: #056c47;
}
.link-primary-200:hover, .link-primary-200:focus {
  color: #045639;
}

.link-primary {
  color: #0b8659;
}
.link-primary:hover, .link-primary:focus {
  color: #096b47;
}

.link-primary-80 {
  color: #3b9e7a;
}
.link-primary-80:hover, .link-primary-80:focus {
  color: #62b195;
}

.link-primary-60 {
  color: #6cb69b;
}
.link-primary-60:hover, .link-primary-60:focus {
  color: #89c5af;
}

.link-primary-40 {
  color: #9bcfbd;
}
.link-primary-40:hover, .link-primary-40:focus {
  color: #afd9ca;
}

.link-primary-30 {
  color: #b6dbce;
}
.link-primary-30:hover, .link-primary-30:focus {
  color: #c5e2d8;
}

.link-primary-10 {
  color: #e8f4ef;
}
.link-primary-10:hover, .link-primary-10:focus {
  color: #edf6f2;
}

.link-gray-100 {
  color: #737377;
}
.link-gray-100:hover, .link-gray-100:focus {
  color: #5c5c5f;
}

.link-gray-80 {
  color: #abadae;
}
.link-gray-80:hover, .link-gray-80:focus {
  color: #bcbdbe;
}

.link-gray-40 {
  color: #d4d4d4;
}
.link-gray-40:hover, .link-gray-40:focus {
  color: #dddddd;
}

.link-gray-20 {
  color: #e8e8e8;
}
.link-gray-20:hover, .link-gray-20:focus {
  color: #ededed;
}

.link-gray-10 {
  color: #f5f5f5;
}
.link-gray-10:hover, .link-gray-10:focus {
  color: #f7f7f7;
}

.link-red-10 {
  color: #fae9ea;
}
.link-red-10:hover, .link-red-10:focus {
  color: #fbedee;
}

.link-red-40 {
  color: #eba6ab;
}
.link-red-40:hover, .link-red-40:focus {
  color: #efb8bc;
}

.link-yellow-10 {
  color: #fcf4e6;
}
.link-yellow-10:hover, .link-yellow-10:focus {
  color: #fdf6eb;
}

.link-yellow-40 {
  color: #d98f00;
}
.link-yellow-40:hover, .link-yellow-40:focus {
  color: #e1a533;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 1;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.border {
  border: 1px solid transparent !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid transparent !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid transparent !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid transparent !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid transparent !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-red {
  border-color: #cc212d !important;
}

.border-yellow {
  border-color: #d98f00 !important;
}

.border-green {
  border-color: #056c47 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-black {
  border-color: #000 !important;
}

.border-gray {
  border-color: #818181 !important;
}

.border-blue {
  border-color: #319cff !important;
}

.border-primary-200 {
  border-color: #056c47 !important;
}

.border-primary {
  border-color: #0b8659 !important;
}

.border-primary-80 {
  border-color: #3b9e7a !important;
}

.border-primary-60 {
  border-color: #6cb69b !important;
}

.border-primary-40 {
  border-color: #9bcfbd !important;
}

.border-primary-30 {
  border-color: #b6dbce !important;
}

.border-primary-10 {
  border-color: #e8f4ef !important;
}

.border-gray-100 {
  border-color: #737377 !important;
}

.border-gray-80 {
  border-color: #abadae !important;
}

.border-gray-40 {
  border-color: #d4d4d4 !important;
}

.border-gray-20 {
  border-color: #e8e8e8 !important;
}

.border-gray-10 {
  border-color: #f5f5f5 !important;
}

.border-red-10 {
  border-color: #fae9ea !important;
}

.border-red-40 {
  border-color: #eba6ab !important;
}

.border-yellow-10 {
  border-color: #fcf4e6 !important;
}

.border-yellow-40 {
  border-color: #d98f00 !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.vh-100 {
  height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-11 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}

.mx-12 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-13 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}

.mx-14 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-15 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-16 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-13 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-15 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-13 {
  margin-top: 3.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-15 {
  margin-top: 3.75rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mr-7 {
  margin-right: 1.75rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mr-9 {
  margin-right: 2.25rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mr-11 {
  margin-right: 2.75rem !important;
}

.mr-12 {
  margin-right: 3rem !important;
}

.mr-13 {
  margin-right: 3.25rem !important;
}

.mr-14 {
  margin-right: 3.5rem !important;
}

.mr-15 {
  margin-right: 3.75rem !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-13 {
  margin-bottom: 3.25rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-15 {
  margin-bottom: 3.75rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.ml-7 {
  margin-left: 1.75rem !important;
}

.ml-8 {
  margin-left: 2rem !important;
}

.ml-9 {
  margin-left: 2.25rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.ml-11 {
  margin-left: 2.75rem !important;
}

.ml-12 {
  margin-left: 3rem !important;
}

.ml-13 {
  margin-left: 3.25rem !important;
}

.ml-14 {
  margin-left: 3.5rem !important;
}

.ml-15 {
  margin-left: 3.75rem !important;
}

.ml-16 {
  margin-left: 4rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n11 {
  margin: -2.75rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.m-n13 {
  margin: -3.25rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.m-n15 {
  margin: -3.75rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n7 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}

.mx-n8 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n9 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n10 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n11 {
  margin-right: -2.75rem !important;
  margin-left: -2.75rem !important;
}

.mx-n12 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n13 {
  margin-right: -3.25rem !important;
  margin-left: -3.25rem !important;
}

.mx-n14 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n15 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n16 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n13 {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}

.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n15 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n9 {
  margin-top: -2.25rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n11 {
  margin-top: -2.75rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.mt-n13 {
  margin-top: -3.25rem !important;
}

.mt-n14 {
  margin-top: -3.5rem !important;
}

.mt-n15 {
  margin-top: -3.75rem !important;
}

.mt-n16 {
  margin-top: -4rem !important;
}

.mr-n1 {
  margin-right: -0.25rem !important;
}

.mr-n2 {
  margin-right: -0.5rem !important;
}

.mr-n3 {
  margin-right: -0.75rem !important;
}

.mr-n4 {
  margin-right: -1rem !important;
}

.mr-n5 {
  margin-right: -1.25rem !important;
}

.mr-n6 {
  margin-right: -1.5rem !important;
}

.mr-n7 {
  margin-right: -1.75rem !important;
}

.mr-n8 {
  margin-right: -2rem !important;
}

.mr-n9 {
  margin-right: -2.25rem !important;
}

.mr-n10 {
  margin-right: -2.5rem !important;
}

.mr-n11 {
  margin-right: -2.75rem !important;
}

.mr-n12 {
  margin-right: -3rem !important;
}

.mr-n13 {
  margin-right: -3.25rem !important;
}

.mr-n14 {
  margin-right: -3.5rem !important;
}

.mr-n15 {
  margin-right: -3.75rem !important;
}

.mr-n16 {
  margin-right: -4rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n9 {
  margin-bottom: -2.25rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n11 {
  margin-bottom: -2.75rem !important;
}

.mb-n12 {
  margin-bottom: -3rem !important;
}

.mb-n13 {
  margin-bottom: -3.25rem !important;
}

.mb-n14 {
  margin-bottom: -3.5rem !important;
}

.mb-n15 {
  margin-bottom: -3.75rem !important;
}

.mb-n16 {
  margin-bottom: -4rem !important;
}

.ml-n1 {
  margin-left: -0.25rem !important;
}

.ml-n2 {
  margin-left: -0.5rem !important;
}

.ml-n3 {
  margin-left: -0.75rem !important;
}

.ml-n4 {
  margin-left: -1rem !important;
}

.ml-n5 {
  margin-left: -1.25rem !important;
}

.ml-n6 {
  margin-left: -1.5rem !important;
}

.ml-n7 {
  margin-left: -1.75rem !important;
}

.ml-n8 {
  margin-left: -2rem !important;
}

.ml-n9 {
  margin-left: -2.25rem !important;
}

.ml-n10 {
  margin-left: -2.5rem !important;
}

.ml-n11 {
  margin-left: -2.75rem !important;
}

.ml-n12 {
  margin-left: -3rem !important;
}

.ml-n13 {
  margin-left: -3.25rem !important;
}

.ml-n14 {
  margin-left: -3.5rem !important;
}

.ml-n15 {
  margin-left: -3.75rem !important;
}

.ml-n16 {
  margin-left: -4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-11 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}

.px-12 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-13 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}

.px-14 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-15 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-16 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-13 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-15 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-13 {
  padding-top: 3.25rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.pr-7 {
  padding-right: 1.75rem !important;
}

.pr-8 {
  padding-right: 2rem !important;
}

.pr-9 {
  padding-right: 2.25rem !important;
}

.pr-10 {
  padding-right: 2.5rem !important;
}

.pr-11 {
  padding-right: 2.75rem !important;
}

.pr-12 {
  padding-right: 3rem !important;
}

.pr-13 {
  padding-right: 3.25rem !important;
}

.pr-14 {
  padding-right: 3.5rem !important;
}

.pr-15 {
  padding-right: 3.75rem !important;
}

.pr-16 {
  padding-right: 4rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-13 {
  padding-bottom: 3.25rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-15 {
  padding-bottom: 3.75rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pl-7 {
  padding-left: 1.75rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.pl-9 {
  padding-left: 2.25rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.pl-11 {
  padding-left: 2.75rem !important;
}

.pl-12 {
  padding-left: 3rem !important;
}

.pl-13 {
  padding-left: 3.25rem !important;
}

.pl-14 {
  padding-left: 3.5rem !important;
}

.pl-15 {
  padding-left: 3.75rem !important;
}

.pl-16 {
  padding-left: 4rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1 !important;
}

.lh-base {
  line-height: 1.4 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-200-rgb), var(--bs-text-opacity)) !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-80 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-80-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-60 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-60-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-40 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-40-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-30 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-30-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-10 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-10-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-100-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-80 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-80-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-40 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-40-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-20 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-20-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-10 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-10-rgb), var(--bs-text-opacity)) !important;
}

.text-red-10 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-10-rgb), var(--bs-text-opacity)) !important;
}

.text-red-40 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-40-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-10 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-10-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-40 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-40-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #818181 !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-80 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-80-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-60 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-60-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-40 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-40-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-30 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-30-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-10 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-10-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-80 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-80-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-40 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-40-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-20 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-20-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-10 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-10-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-10 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-10-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-40 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-40-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-10 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-10-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-40 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-40-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-inherit {
  cursor: inherit !important;
}

.rounded {
  border-radius: 0.125rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.25rem !important;
}

.rounded-2 {
  border-radius: 0.125rem !important;
}

.rounded-3 {
  border-radius: 0.75rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 2.75rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-9 {
    gap: 2.25rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-11 {
    gap: 2.75rem !important;
  }

  .gap-sm-12 {
    gap: 3rem !important;
  }

  .gap-sm-13 {
    gap: 3.25rem !important;
  }

  .gap-sm-14 {
    gap: 3.5rem !important;
  }

  .gap-sm-15 {
    gap: 3.75rem !important;
  }

  .gap-sm-16 {
    gap: 4rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-9 {
    margin: 2.25rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-11 {
    margin: 2.75rem !important;
  }

  .m-sm-12 {
    margin: 3rem !important;
  }

  .m-sm-13 {
    margin: 3.25rem !important;
  }

  .m-sm-14 {
    margin: 3.5rem !important;
  }

  .m-sm-15 {
    margin: 3.75rem !important;
  }

  .m-sm-16 {
    margin: 4rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-sm-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-sm-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-sm-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 2.75rem !important;
  }

  .mt-sm-12 {
    margin-top: 3rem !important;
  }

  .mt-sm-13 {
    margin-top: 3.25rem !important;
  }

  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 3.75rem !important;
  }

  .mt-sm-16 {
    margin-top: 4rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mr-sm-3 {
    margin-right: 0.75rem !important;
  }

  .mr-sm-4 {
    margin-right: 1rem !important;
  }

  .mr-sm-5 {
    margin-right: 1.25rem !important;
  }

  .mr-sm-6 {
    margin-right: 1.5rem !important;
  }

  .mr-sm-7 {
    margin-right: 1.75rem !important;
  }

  .mr-sm-8 {
    margin-right: 2rem !important;
  }

  .mr-sm-9 {
    margin-right: 2.25rem !important;
  }

  .mr-sm-10 {
    margin-right: 2.5rem !important;
  }

  .mr-sm-11 {
    margin-right: 2.75rem !important;
  }

  .mr-sm-12 {
    margin-right: 3rem !important;
  }

  .mr-sm-13 {
    margin-right: 3.25rem !important;
  }

  .mr-sm-14 {
    margin-right: 3.5rem !important;
  }

  .mr-sm-15 {
    margin-right: 3.75rem !important;
  }

  .mr-sm-16 {
    margin-right: 4rem !important;
  }

  .mr-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ml-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ml-sm-4 {
    margin-left: 1rem !important;
  }

  .ml-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ml-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ml-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ml-sm-8 {
    margin-left: 2rem !important;
  }

  .ml-sm-9 {
    margin-left: 2.25rem !important;
  }

  .ml-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ml-sm-11 {
    margin-left: 2.75rem !important;
  }

  .ml-sm-12 {
    margin-left: 3rem !important;
  }

  .ml-sm-13 {
    margin-left: 3.25rem !important;
  }

  .ml-sm-14 {
    margin-left: 3.5rem !important;
  }

  .ml-sm-15 {
    margin-left: 3.75rem !important;
  }

  .ml-sm-16 {
    margin-left: 4rem !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n9 {
    margin: -2.25rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .m-sm-n11 {
    margin: -2.75rem !important;
  }

  .m-sm-n12 {
    margin: -3rem !important;
  }

  .m-sm-n13 {
    margin: -3.25rem !important;
  }

  .m-sm-n14 {
    margin: -3.5rem !important;
  }

  .m-sm-n15 {
    margin: -3.75rem !important;
  }

  .m-sm-n16 {
    margin: -4rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-sm-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-sm-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-sm-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-sm-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-sm-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-sm-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-sm-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-sm-n12 {
    margin-top: -3rem !important;
  }

  .mt-sm-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-sm-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-sm-n16 {
    margin-top: -4rem !important;
  }

  .mr-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .mr-sm-n4 {
    margin-right: -1rem !important;
  }

  .mr-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .mr-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .mr-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .mr-sm-n8 {
    margin-right: -2rem !important;
  }

  .mr-sm-n9 {
    margin-right: -2.25rem !important;
  }

  .mr-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .mr-sm-n11 {
    margin-right: -2.75rem !important;
  }

  .mr-sm-n12 {
    margin-right: -3rem !important;
  }

  .mr-sm-n13 {
    margin-right: -3.25rem !important;
  }

  .mr-sm-n14 {
    margin-right: -3.5rem !important;
  }

  .mr-sm-n15 {
    margin-right: -3.75rem !important;
  }

  .mr-sm-n16 {
    margin-right: -4rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -4rem !important;
  }

  .ml-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .ml-sm-n4 {
    margin-left: -1rem !important;
  }

  .ml-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ml-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ml-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ml-sm-n8 {
    margin-left: -2rem !important;
  }

  .ml-sm-n9 {
    margin-left: -2.25rem !important;
  }

  .ml-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .ml-sm-n11 {
    margin-left: -2.75rem !important;
  }

  .ml-sm-n12 {
    margin-left: -3rem !important;
  }

  .ml-sm-n13 {
    margin-left: -3.25rem !important;
  }

  .ml-sm-n14 {
    margin-left: -3.5rem !important;
  }

  .ml-sm-n15 {
    margin-left: -3.75rem !important;
  }

  .ml-sm-n16 {
    margin-left: -4rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-9 {
    padding: 2.25rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-11 {
    padding: 2.75rem !important;
  }

  .p-sm-12 {
    padding: 3rem !important;
  }

  .p-sm-13 {
    padding: 3.25rem !important;
  }

  .p-sm-14 {
    padding: 3.5rem !important;
  }

  .p-sm-15 {
    padding: 3.75rem !important;
  }

  .p-sm-16 {
    padding: 4rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-sm-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-sm-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-sm-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 2.75rem !important;
  }

  .pt-sm-12 {
    padding-top: 3rem !important;
  }

  .pt-sm-13 {
    padding-top: 3.25rem !important;
  }

  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 3.75rem !important;
  }

  .pt-sm-16 {
    padding-top: 4rem !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pr-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pr-sm-4 {
    padding-right: 1rem !important;
  }

  .pr-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pr-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pr-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pr-sm-8 {
    padding-right: 2rem !important;
  }

  .pr-sm-9 {
    padding-right: 2.25rem !important;
  }

  .pr-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pr-sm-11 {
    padding-right: 2.75rem !important;
  }

  .pr-sm-12 {
    padding-right: 3rem !important;
  }

  .pr-sm-13 {
    padding-right: 3.25rem !important;
  }

  .pr-sm-14 {
    padding-right: 3.5rem !important;
  }

  .pr-sm-15 {
    padding-right: 3.75rem !important;
  }

  .pr-sm-16 {
    padding-right: 4rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }

  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }

  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }

  .pl-sm-3 {
    padding-left: 0.75rem !important;
  }

  .pl-sm-4 {
    padding-left: 1rem !important;
  }

  .pl-sm-5 {
    padding-left: 1.25rem !important;
  }

  .pl-sm-6 {
    padding-left: 1.5rem !important;
  }

  .pl-sm-7 {
    padding-left: 1.75rem !important;
  }

  .pl-sm-8 {
    padding-left: 2rem !important;
  }

  .pl-sm-9 {
    padding-left: 2.25rem !important;
  }

  .pl-sm-10 {
    padding-left: 2.5rem !important;
  }

  .pl-sm-11 {
    padding-left: 2.75rem !important;
  }

  .pl-sm-12 {
    padding-left: 3rem !important;
  }

  .pl-sm-13 {
    padding-left: 3.25rem !important;
  }

  .pl-sm-14 {
    padding-left: 3.5rem !important;
  }

  .pl-sm-15 {
    padding-left: 3.75rem !important;
  }

  .pl-sm-16 {
    padding-left: 4rem !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-9 {
    gap: 2.25rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-11 {
    gap: 2.75rem !important;
  }

  .gap-md-12 {
    gap: 3rem !important;
  }

  .gap-md-13 {
    gap: 3.25rem !important;
  }

  .gap-md-14 {
    gap: 3.5rem !important;
  }

  .gap-md-15 {
    gap: 3.75rem !important;
  }

  .gap-md-16 {
    gap: 4rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-9 {
    margin: 2.25rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-11 {
    margin: 2.75rem !important;
  }

  .m-md-12 {
    margin: 3rem !important;
  }

  .m-md-13 {
    margin: 3.25rem !important;
  }

  .m-md-14 {
    margin: 3.5rem !important;
  }

  .m-md-15 {
    margin: 3.75rem !important;
  }

  .m-md-16 {
    margin: 4rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-md-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-md-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-md-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-9 {
    margin-top: 2.25rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-11 {
    margin-top: 2.75rem !important;
  }

  .mt-md-12 {
    margin-top: 3rem !important;
  }

  .mt-md-13 {
    margin-top: 3.25rem !important;
  }

  .mt-md-14 {
    margin-top: 3.5rem !important;
  }

  .mt-md-15 {
    margin-top: 3.75rem !important;
  }

  .mt-md-16 {
    margin-top: 4rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mr-md-1 {
    margin-right: 0.25rem !important;
  }

  .mr-md-2 {
    margin-right: 0.5rem !important;
  }

  .mr-md-3 {
    margin-right: 0.75rem !important;
  }

  .mr-md-4 {
    margin-right: 1rem !important;
  }

  .mr-md-5 {
    margin-right: 1.25rem !important;
  }

  .mr-md-6 {
    margin-right: 1.5rem !important;
  }

  .mr-md-7 {
    margin-right: 1.75rem !important;
  }

  .mr-md-8 {
    margin-right: 2rem !important;
  }

  .mr-md-9 {
    margin-right: 2.25rem !important;
  }

  .mr-md-10 {
    margin-right: 2.5rem !important;
  }

  .mr-md-11 {
    margin-right: 2.75rem !important;
  }

  .mr-md-12 {
    margin-right: 3rem !important;
  }

  .mr-md-13 {
    margin-right: 3.25rem !important;
  }

  .mr-md-14 {
    margin-right: 3.5rem !important;
  }

  .mr-md-15 {
    margin-right: 3.75rem !important;
  }

  .mr-md-16 {
    margin-right: 4rem !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3rem !important;
  }

  .mb-md-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-md-16 {
    margin-bottom: 4rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .ml-md-1 {
    margin-left: 0.25rem !important;
  }

  .ml-md-2 {
    margin-left: 0.5rem !important;
  }

  .ml-md-3 {
    margin-left: 0.75rem !important;
  }

  .ml-md-4 {
    margin-left: 1rem !important;
  }

  .ml-md-5 {
    margin-left: 1.25rem !important;
  }

  .ml-md-6 {
    margin-left: 1.5rem !important;
  }

  .ml-md-7 {
    margin-left: 1.75rem !important;
  }

  .ml-md-8 {
    margin-left: 2rem !important;
  }

  .ml-md-9 {
    margin-left: 2.25rem !important;
  }

  .ml-md-10 {
    margin-left: 2.5rem !important;
  }

  .ml-md-11 {
    margin-left: 2.75rem !important;
  }

  .ml-md-12 {
    margin-left: 3rem !important;
  }

  .ml-md-13 {
    margin-left: 3.25rem !important;
  }

  .ml-md-14 {
    margin-left: 3.5rem !important;
  }

  .ml-md-15 {
    margin-left: 3.75rem !important;
  }

  .ml-md-16 {
    margin-left: 4rem !important;
  }

  .ml-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n9 {
    margin: -2.25rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .m-md-n11 {
    margin: -2.75rem !important;
  }

  .m-md-n12 {
    margin: -3rem !important;
  }

  .m-md-n13 {
    margin: -3.25rem !important;
  }

  .m-md-n14 {
    margin: -3.5rem !important;
  }

  .m-md-n15 {
    margin: -3.75rem !important;
  }

  .m-md-n16 {
    margin: -4rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-md-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-md-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-md-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-md-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-md-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-md-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-md-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-md-n12 {
    margin-top: -3rem !important;
  }

  .mt-md-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-md-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-md-n16 {
    margin-top: -4rem !important;
  }

  .mr-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-md-n3 {
    margin-right: -0.75rem !important;
  }

  .mr-md-n4 {
    margin-right: -1rem !important;
  }

  .mr-md-n5 {
    margin-right: -1.25rem !important;
  }

  .mr-md-n6 {
    margin-right: -1.5rem !important;
  }

  .mr-md-n7 {
    margin-right: -1.75rem !important;
  }

  .mr-md-n8 {
    margin-right: -2rem !important;
  }

  .mr-md-n9 {
    margin-right: -2.25rem !important;
  }

  .mr-md-n10 {
    margin-right: -2.5rem !important;
  }

  .mr-md-n11 {
    margin-right: -2.75rem !important;
  }

  .mr-md-n12 {
    margin-right: -3rem !important;
  }

  .mr-md-n13 {
    margin-right: -3.25rem !important;
  }

  .mr-md-n14 {
    margin-right: -3.5rem !important;
  }

  .mr-md-n15 {
    margin-right: -3.75rem !important;
  }

  .mr-md-n16 {
    margin-right: -4rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -4rem !important;
  }

  .ml-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-md-n3 {
    margin-left: -0.75rem !important;
  }

  .ml-md-n4 {
    margin-left: -1rem !important;
  }

  .ml-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ml-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ml-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ml-md-n8 {
    margin-left: -2rem !important;
  }

  .ml-md-n9 {
    margin-left: -2.25rem !important;
  }

  .ml-md-n10 {
    margin-left: -2.5rem !important;
  }

  .ml-md-n11 {
    margin-left: -2.75rem !important;
  }

  .ml-md-n12 {
    margin-left: -3rem !important;
  }

  .ml-md-n13 {
    margin-left: -3.25rem !important;
  }

  .ml-md-n14 {
    margin-left: -3.5rem !important;
  }

  .ml-md-n15 {
    margin-left: -3.75rem !important;
  }

  .ml-md-n16 {
    margin-left: -4rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-9 {
    padding: 2.25rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-11 {
    padding: 2.75rem !important;
  }

  .p-md-12 {
    padding: 3rem !important;
  }

  .p-md-13 {
    padding: 3.25rem !important;
  }

  .p-md-14 {
    padding: 3.5rem !important;
  }

  .p-md-15 {
    padding: 3.75rem !important;
  }

  .p-md-16 {
    padding: 4rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-md-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-md-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-md-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-9 {
    padding-top: 2.25rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-11 {
    padding-top: 2.75rem !important;
  }

  .pt-md-12 {
    padding-top: 3rem !important;
  }

  .pt-md-13 {
    padding-top: 3.25rem !important;
  }

  .pt-md-14 {
    padding-top: 3.5rem !important;
  }

  .pt-md-15 {
    padding-top: 3.75rem !important;
  }

  .pt-md-16 {
    padding-top: 4rem !important;
  }

  .pr-md-0 {
    padding-right: 0 !important;
  }

  .pr-md-1 {
    padding-right: 0.25rem !important;
  }

  .pr-md-2 {
    padding-right: 0.5rem !important;
  }

  .pr-md-3 {
    padding-right: 0.75rem !important;
  }

  .pr-md-4 {
    padding-right: 1rem !important;
  }

  .pr-md-5 {
    padding-right: 1.25rem !important;
  }

  .pr-md-6 {
    padding-right: 1.5rem !important;
  }

  .pr-md-7 {
    padding-right: 1.75rem !important;
  }

  .pr-md-8 {
    padding-right: 2rem !important;
  }

  .pr-md-9 {
    padding-right: 2.25rem !important;
  }

  .pr-md-10 {
    padding-right: 2.5rem !important;
  }

  .pr-md-11 {
    padding-right: 2.75rem !important;
  }

  .pr-md-12 {
    padding-right: 3rem !important;
  }

  .pr-md-13 {
    padding-right: 3.25rem !important;
  }

  .pr-md-14 {
    padding-right: 3.5rem !important;
  }

  .pr-md-15 {
    padding-right: 3.75rem !important;
  }

  .pr-md-16 {
    padding-right: 4rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3rem !important;
  }

  .pb-md-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-md-16 {
    padding-bottom: 4rem !important;
  }

  .pl-md-0 {
    padding-left: 0 !important;
  }

  .pl-md-1 {
    padding-left: 0.25rem !important;
  }

  .pl-md-2 {
    padding-left: 0.5rem !important;
  }

  .pl-md-3 {
    padding-left: 0.75rem !important;
  }

  .pl-md-4 {
    padding-left: 1rem !important;
  }

  .pl-md-5 {
    padding-left: 1.25rem !important;
  }

  .pl-md-6 {
    padding-left: 1.5rem !important;
  }

  .pl-md-7 {
    padding-left: 1.75rem !important;
  }

  .pl-md-8 {
    padding-left: 2rem !important;
  }

  .pl-md-9 {
    padding-left: 2.25rem !important;
  }

  .pl-md-10 {
    padding-left: 2.5rem !important;
  }

  .pl-md-11 {
    padding-left: 2.75rem !important;
  }

  .pl-md-12 {
    padding-left: 3rem !important;
  }

  .pl-md-13 {
    padding-left: 3.25rem !important;
  }

  .pl-md-14 {
    padding-left: 3.5rem !important;
  }

  .pl-md-15 {
    padding-left: 3.75rem !important;
  }

  .pl-md-16 {
    padding-left: 4rem !important;
  }

  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-9 {
    gap: 2.25rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-11 {
    gap: 2.75rem !important;
  }

  .gap-lg-12 {
    gap: 3rem !important;
  }

  .gap-lg-13 {
    gap: 3.25rem !important;
  }

  .gap-lg-14 {
    gap: 3.5rem !important;
  }

  .gap-lg-15 {
    gap: 3.75rem !important;
  }

  .gap-lg-16 {
    gap: 4rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-9 {
    margin: 2.25rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-11 {
    margin: 2.75rem !important;
  }

  .m-lg-12 {
    margin: 3rem !important;
  }

  .m-lg-13 {
    margin: 3.25rem !important;
  }

  .m-lg-14 {
    margin: 3.5rem !important;
  }

  .m-lg-15 {
    margin: 3.75rem !important;
  }

  .m-lg-16 {
    margin: 4rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-lg-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-lg-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-lg-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 2.75rem !important;
  }

  .mt-lg-12 {
    margin-top: 3rem !important;
  }

  .mt-lg-13 {
    margin-top: 3.25rem !important;
  }

  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 3.75rem !important;
  }

  .mt-lg-16 {
    margin-top: 4rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mr-lg-3 {
    margin-right: 0.75rem !important;
  }

  .mr-lg-4 {
    margin-right: 1rem !important;
  }

  .mr-lg-5 {
    margin-right: 1.25rem !important;
  }

  .mr-lg-6 {
    margin-right: 1.5rem !important;
  }

  .mr-lg-7 {
    margin-right: 1.75rem !important;
  }

  .mr-lg-8 {
    margin-right: 2rem !important;
  }

  .mr-lg-9 {
    margin-right: 2.25rem !important;
  }

  .mr-lg-10 {
    margin-right: 2.5rem !important;
  }

  .mr-lg-11 {
    margin-right: 2.75rem !important;
  }

  .mr-lg-12 {
    margin-right: 3rem !important;
  }

  .mr-lg-13 {
    margin-right: 3.25rem !important;
  }

  .mr-lg-14 {
    margin-right: 3.5rem !important;
  }

  .mr-lg-15 {
    margin-right: 3.75rem !important;
  }

  .mr-lg-16 {
    margin-right: 4rem !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ml-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ml-lg-4 {
    margin-left: 1rem !important;
  }

  .ml-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ml-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ml-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ml-lg-8 {
    margin-left: 2rem !important;
  }

  .ml-lg-9 {
    margin-left: 2.25rem !important;
  }

  .ml-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ml-lg-11 {
    margin-left: 2.75rem !important;
  }

  .ml-lg-12 {
    margin-left: 3rem !important;
  }

  .ml-lg-13 {
    margin-left: 3.25rem !important;
  }

  .ml-lg-14 {
    margin-left: 3.5rem !important;
  }

  .ml-lg-15 {
    margin-left: 3.75rem !important;
  }

  .ml-lg-16 {
    margin-left: 4rem !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n9 {
    margin: -2.25rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .m-lg-n11 {
    margin: -2.75rem !important;
  }

  .m-lg-n12 {
    margin: -3rem !important;
  }

  .m-lg-n13 {
    margin: -3.25rem !important;
  }

  .m-lg-n14 {
    margin: -3.5rem !important;
  }

  .m-lg-n15 {
    margin: -3.75rem !important;
  }

  .m-lg-n16 {
    margin: -4rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-lg-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-lg-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-lg-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-lg-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-lg-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-lg-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-lg-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-lg-n12 {
    margin-top: -3rem !important;
  }

  .mt-lg-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-lg-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-lg-n16 {
    margin-top: -4rem !important;
  }

  .mr-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .mr-lg-n4 {
    margin-right: -1rem !important;
  }

  .mr-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .mr-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .mr-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .mr-lg-n8 {
    margin-right: -2rem !important;
  }

  .mr-lg-n9 {
    margin-right: -2.25rem !important;
  }

  .mr-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .mr-lg-n11 {
    margin-right: -2.75rem !important;
  }

  .mr-lg-n12 {
    margin-right: -3rem !important;
  }

  .mr-lg-n13 {
    margin-right: -3.25rem !important;
  }

  .mr-lg-n14 {
    margin-right: -3.5rem !important;
  }

  .mr-lg-n15 {
    margin-right: -3.75rem !important;
  }

  .mr-lg-n16 {
    margin-right: -4rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -4rem !important;
  }

  .ml-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .ml-lg-n4 {
    margin-left: -1rem !important;
  }

  .ml-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ml-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ml-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ml-lg-n8 {
    margin-left: -2rem !important;
  }

  .ml-lg-n9 {
    margin-left: -2.25rem !important;
  }

  .ml-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .ml-lg-n11 {
    margin-left: -2.75rem !important;
  }

  .ml-lg-n12 {
    margin-left: -3rem !important;
  }

  .ml-lg-n13 {
    margin-left: -3.25rem !important;
  }

  .ml-lg-n14 {
    margin-left: -3.5rem !important;
  }

  .ml-lg-n15 {
    margin-left: -3.75rem !important;
  }

  .ml-lg-n16 {
    margin-left: -4rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-9 {
    padding: 2.25rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-11 {
    padding: 2.75rem !important;
  }

  .p-lg-12 {
    padding: 3rem !important;
  }

  .p-lg-13 {
    padding: 3.25rem !important;
  }

  .p-lg-14 {
    padding: 3.5rem !important;
  }

  .p-lg-15 {
    padding: 3.75rem !important;
  }

  .p-lg-16 {
    padding: 4rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-lg-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-lg-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-lg-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 2.75rem !important;
  }

  .pt-lg-12 {
    padding-top: 3rem !important;
  }

  .pt-lg-13 {
    padding-top: 3.25rem !important;
  }

  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 3.75rem !important;
  }

  .pt-lg-16 {
    padding-top: 4rem !important;
  }

  .pr-lg-0 {
    padding-right: 0 !important;
  }

  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pr-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pr-lg-4 {
    padding-right: 1rem !important;
  }

  .pr-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pr-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pr-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pr-lg-8 {
    padding-right: 2rem !important;
  }

  .pr-lg-9 {
    padding-right: 2.25rem !important;
  }

  .pr-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pr-lg-11 {
    padding-right: 2.75rem !important;
  }

  .pr-lg-12 {
    padding-right: 3rem !important;
  }

  .pr-lg-13 {
    padding-right: 3.25rem !important;
  }

  .pr-lg-14 {
    padding-right: 3.5rem !important;
  }

  .pr-lg-15 {
    padding-right: 3.75rem !important;
  }

  .pr-lg-16 {
    padding-right: 4rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }

  .pl-lg-0 {
    padding-left: 0 !important;
  }

  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }

  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }

  .pl-lg-3 {
    padding-left: 0.75rem !important;
  }

  .pl-lg-4 {
    padding-left: 1rem !important;
  }

  .pl-lg-5 {
    padding-left: 1.25rem !important;
  }

  .pl-lg-6 {
    padding-left: 1.5rem !important;
  }

  .pl-lg-7 {
    padding-left: 1.75rem !important;
  }

  .pl-lg-8 {
    padding-left: 2rem !important;
  }

  .pl-lg-9 {
    padding-left: 2.25rem !important;
  }

  .pl-lg-10 {
    padding-left: 2.5rem !important;
  }

  .pl-lg-11 {
    padding-left: 2.75rem !important;
  }

  .pl-lg-12 {
    padding-left: 3rem !important;
  }

  .pl-lg-13 {
    padding-left: 3.25rem !important;
  }

  .pl-lg-14 {
    padding-left: 3.5rem !important;
  }

  .pl-lg-15 {
    padding-left: 3.75rem !important;
  }

  .pl-lg-16 {
    padding-left: 4rem !important;
  }

  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-9 {
    gap: 2.25rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-11 {
    gap: 2.75rem !important;
  }

  .gap-xl-12 {
    gap: 3rem !important;
  }

  .gap-xl-13 {
    gap: 3.25rem !important;
  }

  .gap-xl-14 {
    gap: 3.5rem !important;
  }

  .gap-xl-15 {
    gap: 3.75rem !important;
  }

  .gap-xl-16 {
    gap: 4rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-9 {
    margin: 2.25rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-11 {
    margin: 2.75rem !important;
  }

  .m-xl-12 {
    margin: 3rem !important;
  }

  .m-xl-13 {
    margin: 3.25rem !important;
  }

  .m-xl-14 {
    margin: 3.5rem !important;
  }

  .m-xl-15 {
    margin: 3.75rem !important;
  }

  .m-xl-16 {
    margin: 4rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xl-12 {
    margin-top: 3rem !important;
  }

  .mt-xl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xl-16 {
    margin-top: 4rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mr-xl-3 {
    margin-right: 0.75rem !important;
  }

  .mr-xl-4 {
    margin-right: 1rem !important;
  }

  .mr-xl-5 {
    margin-right: 1.25rem !important;
  }

  .mr-xl-6 {
    margin-right: 1.5rem !important;
  }

  .mr-xl-7 {
    margin-right: 1.75rem !important;
  }

  .mr-xl-8 {
    margin-right: 2rem !important;
  }

  .mr-xl-9 {
    margin-right: 2.25rem !important;
  }

  .mr-xl-10 {
    margin-right: 2.5rem !important;
  }

  .mr-xl-11 {
    margin-right: 2.75rem !important;
  }

  .mr-xl-12 {
    margin-right: 3rem !important;
  }

  .mr-xl-13 {
    margin-right: 3.25rem !important;
  }

  .mr-xl-14 {
    margin-right: 3.5rem !important;
  }

  .mr-xl-15 {
    margin-right: 3.75rem !important;
  }

  .mr-xl-16 {
    margin-right: 4rem !important;
  }

  .mr-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ml-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ml-xl-4 {
    margin-left: 1rem !important;
  }

  .ml-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ml-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ml-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ml-xl-8 {
    margin-left: 2rem !important;
  }

  .ml-xl-9 {
    margin-left: 2.25rem !important;
  }

  .ml-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ml-xl-11 {
    margin-left: 2.75rem !important;
  }

  .ml-xl-12 {
    margin-left: 3rem !important;
  }

  .ml-xl-13 {
    margin-left: 3.25rem !important;
  }

  .ml-xl-14 {
    margin-left: 3.5rem !important;
  }

  .ml-xl-15 {
    margin-left: 3.75rem !important;
  }

  .ml-xl-16 {
    margin-left: 4rem !important;
  }

  .ml-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n9 {
    margin: -2.25rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .m-xl-n11 {
    margin: -2.75rem !important;
  }

  .m-xl-n12 {
    margin: -3rem !important;
  }

  .m-xl-n13 {
    margin: -3.25rem !important;
  }

  .m-xl-n14 {
    margin: -3.5rem !important;
  }

  .m-xl-n15 {
    margin: -3.75rem !important;
  }

  .m-xl-n16 {
    margin: -4rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xl-n16 {
    margin-top: -4rem !important;
  }

  .mr-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .mr-xl-n4 {
    margin-right: -1rem !important;
  }

  .mr-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .mr-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .mr-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .mr-xl-n8 {
    margin-right: -2rem !important;
  }

  .mr-xl-n9 {
    margin-right: -2.25rem !important;
  }

  .mr-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .mr-xl-n11 {
    margin-right: -2.75rem !important;
  }

  .mr-xl-n12 {
    margin-right: -3rem !important;
  }

  .mr-xl-n13 {
    margin-right: -3.25rem !important;
  }

  .mr-xl-n14 {
    margin-right: -3.5rem !important;
  }

  .mr-xl-n15 {
    margin-right: -3.75rem !important;
  }

  .mr-xl-n16 {
    margin-right: -4rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -4rem !important;
  }

  .ml-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .ml-xl-n4 {
    margin-left: -1rem !important;
  }

  .ml-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ml-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ml-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ml-xl-n8 {
    margin-left: -2rem !important;
  }

  .ml-xl-n9 {
    margin-left: -2.25rem !important;
  }

  .ml-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .ml-xl-n11 {
    margin-left: -2.75rem !important;
  }

  .ml-xl-n12 {
    margin-left: -3rem !important;
  }

  .ml-xl-n13 {
    margin-left: -3.25rem !important;
  }

  .ml-xl-n14 {
    margin-left: -3.5rem !important;
  }

  .ml-xl-n15 {
    margin-left: -3.75rem !important;
  }

  .ml-xl-n16 {
    margin-left: -4rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-9 {
    padding: 2.25rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-11 {
    padding: 2.75rem !important;
  }

  .p-xl-12 {
    padding: 3rem !important;
  }

  .p-xl-13 {
    padding: 3.25rem !important;
  }

  .p-xl-14 {
    padding: 3.5rem !important;
  }

  .p-xl-15 {
    padding: 3.75rem !important;
  }

  .p-xl-16 {
    padding: 4rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xl-12 {
    padding-top: 3rem !important;
  }

  .pt-xl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xl-16 {
    padding-top: 4rem !important;
  }

  .pr-xl-0 {
    padding-right: 0 !important;
  }

  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pr-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pr-xl-4 {
    padding-right: 1rem !important;
  }

  .pr-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pr-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pr-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pr-xl-8 {
    padding-right: 2rem !important;
  }

  .pr-xl-9 {
    padding-right: 2.25rem !important;
  }

  .pr-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pr-xl-11 {
    padding-right: 2.75rem !important;
  }

  .pr-xl-12 {
    padding-right: 3rem !important;
  }

  .pr-xl-13 {
    padding-right: 3.25rem !important;
  }

  .pr-xl-14 {
    padding-right: 3.5rem !important;
  }

  .pr-xl-15 {
    padding-right: 3.75rem !important;
  }

  .pr-xl-16 {
    padding-right: 4rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }

  .pl-xl-0 {
    padding-left: 0 !important;
  }

  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }

  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }

  .pl-xl-3 {
    padding-left: 0.75rem !important;
  }

  .pl-xl-4 {
    padding-left: 1rem !important;
  }

  .pl-xl-5 {
    padding-left: 1.25rem !important;
  }

  .pl-xl-6 {
    padding-left: 1.5rem !important;
  }

  .pl-xl-7 {
    padding-left: 1.75rem !important;
  }

  .pl-xl-8 {
    padding-left: 2rem !important;
  }

  .pl-xl-9 {
    padding-left: 2.25rem !important;
  }

  .pl-xl-10 {
    padding-left: 2.5rem !important;
  }

  .pl-xl-11 {
    padding-left: 2.75rem !important;
  }

  .pl-xl-12 {
    padding-left: 3rem !important;
  }

  .pl-xl-13 {
    padding-left: 3.25rem !important;
  }

  .pl-xl-14 {
    padding-left: 3.5rem !important;
  }

  .pl-xl-15 {
    padding-left: 3.75rem !important;
  }

  .pl-xl-16 {
    padding-left: 4rem !important;
  }

  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-9 {
    gap: 2.25rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-11 {
    gap: 2.75rem !important;
  }

  .gap-xxl-12 {
    gap: 3rem !important;
  }

  .gap-xxl-13 {
    gap: 3.25rem !important;
  }

  .gap-xxl-14 {
    gap: 3.5rem !important;
  }

  .gap-xxl-15 {
    gap: 3.75rem !important;
  }

  .gap-xxl-16 {
    gap: 4rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-9 {
    margin: 2.25rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-11 {
    margin: 2.75rem !important;
  }

  .m-xxl-12 {
    margin: 3rem !important;
  }

  .m-xxl-13 {
    margin: 3.25rem !important;
  }

  .m-xxl-14 {
    margin: 3.5rem !important;
  }

  .m-xxl-15 {
    margin: 3.75rem !important;
  }

  .m-xxl-16 {
    margin: 4rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xxl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xxl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xxl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3rem !important;
  }

  .mt-xxl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xxl-16 {
    margin-top: 4rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-0 {
    margin-right: 0 !important;
  }

  .mr-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mr-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mr-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .mr-xxl-4 {
    margin-right: 1rem !important;
  }

  .mr-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .mr-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .mr-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .mr-xxl-8 {
    margin-right: 2rem !important;
  }

  .mr-xxl-9 {
    margin-right: 2.25rem !important;
  }

  .mr-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .mr-xxl-11 {
    margin-right: 2.75rem !important;
  }

  .mr-xxl-12 {
    margin-right: 3rem !important;
  }

  .mr-xxl-13 {
    margin-right: 3.25rem !important;
  }

  .mr-xxl-14 {
    margin-right: 3.5rem !important;
  }

  .mr-xxl-15 {
    margin-right: 3.75rem !important;
  }

  .mr-xxl-16 {
    margin-right: 4rem !important;
  }

  .mr-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-0 {
    margin-left: 0 !important;
  }

  .ml-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ml-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ml-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ml-xxl-4 {
    margin-left: 1rem !important;
  }

  .ml-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ml-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ml-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ml-xxl-8 {
    margin-left: 2rem !important;
  }

  .ml-xxl-9 {
    margin-left: 2.25rem !important;
  }

  .ml-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ml-xxl-11 {
    margin-left: 2.75rem !important;
  }

  .ml-xxl-12 {
    margin-left: 3rem !important;
  }

  .ml-xxl-13 {
    margin-left: 3.25rem !important;
  }

  .ml-xxl-14 {
    margin-left: 3.5rem !important;
  }

  .ml-xxl-15 {
    margin-left: 3.75rem !important;
  }

  .ml-xxl-16 {
    margin-left: 4rem !important;
  }

  .ml-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n9 {
    margin: -2.25rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .m-xxl-n11 {
    margin: -2.75rem !important;
  }

  .m-xxl-n12 {
    margin: -3rem !important;
  }

  .m-xxl-n13 {
    margin: -3.25rem !important;
  }

  .m-xxl-n14 {
    margin: -3.5rem !important;
  }

  .m-xxl-n15 {
    margin: -3.75rem !important;
  }

  .m-xxl-n16 {
    margin: -4rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xxl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xxl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xxl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xxl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -4rem !important;
  }

  .mr-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mr-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mr-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .mr-xxl-n4 {
    margin-right: -1rem !important;
  }

  .mr-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .mr-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .mr-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .mr-xxl-n8 {
    margin-right: -2rem !important;
  }

  .mr-xxl-n9 {
    margin-right: -2.25rem !important;
  }

  .mr-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .mr-xxl-n11 {
    margin-right: -2.75rem !important;
  }

  .mr-xxl-n12 {
    margin-right: -3rem !important;
  }

  .mr-xxl-n13 {
    margin-right: -3.25rem !important;
  }

  .mr-xxl-n14 {
    margin-right: -3.5rem !important;
  }

  .mr-xxl-n15 {
    margin-right: -3.75rem !important;
  }

  .mr-xxl-n16 {
    margin-right: -4rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -4rem !important;
  }

  .ml-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ml-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ml-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .ml-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ml-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ml-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ml-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ml-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ml-xxl-n9 {
    margin-left: -2.25rem !important;
  }

  .ml-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .ml-xxl-n11 {
    margin-left: -2.75rem !important;
  }

  .ml-xxl-n12 {
    margin-left: -3rem !important;
  }

  .ml-xxl-n13 {
    margin-left: -3.25rem !important;
  }

  .ml-xxl-n14 {
    margin-left: -3.5rem !important;
  }

  .ml-xxl-n15 {
    margin-left: -3.75rem !important;
  }

  .ml-xxl-n16 {
    margin-left: -4rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-9 {
    padding: 2.25rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-11 {
    padding: 2.75rem !important;
  }

  .p-xxl-12 {
    padding: 3rem !important;
  }

  .p-xxl-13 {
    padding: 3.25rem !important;
  }

  .p-xxl-14 {
    padding: 3.5rem !important;
  }

  .p-xxl-15 {
    padding: 3.75rem !important;
  }

  .p-xxl-16 {
    padding: 4rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xxl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xxl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xxl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3rem !important;
  }

  .pt-xxl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xxl-16 {
    padding-top: 4rem !important;
  }

  .pr-xxl-0 {
    padding-right: 0 !important;
  }

  .pr-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pr-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pr-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pr-xxl-4 {
    padding-right: 1rem !important;
  }

  .pr-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pr-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pr-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pr-xxl-8 {
    padding-right: 2rem !important;
  }

  .pr-xxl-9 {
    padding-right: 2.25rem !important;
  }

  .pr-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pr-xxl-11 {
    padding-right: 2.75rem !important;
  }

  .pr-xxl-12 {
    padding-right: 3rem !important;
  }

  .pr-xxl-13 {
    padding-right: 3.25rem !important;
  }

  .pr-xxl-14 {
    padding-right: 3.5rem !important;
  }

  .pr-xxl-15 {
    padding-right: 3.75rem !important;
  }

  .pr-xxl-16 {
    padding-right: 4rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }

  .pl-xxl-0 {
    padding-left: 0 !important;
  }

  .pl-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .pl-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .pl-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .pl-xxl-4 {
    padding-left: 1rem !important;
  }

  .pl-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .pl-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .pl-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .pl-xxl-8 {
    padding-left: 2rem !important;
  }

  .pl-xxl-9 {
    padding-left: 2.25rem !important;
  }

  .pl-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .pl-xxl-11 {
    padding-left: 2.75rem !important;
  }

  .pl-xxl-12 {
    padding-left: 3rem !important;
  }

  .pl-xxl-13 {
    padding-left: 3.25rem !important;
  }

  .pl-xxl-14 {
    padding-left: 3.5rem !important;
  }

  .pl-xxl-15 {
    padding-left: 3.75rem !important;
  }

  .pl-xxl-16 {
    padding-left: 4rem !important;
  }

  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.75rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.dot {
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background-color: #818181;
}

/**
* lazy loading blur
*/
.lazy-load-image-background.blur {
  filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  filter: blur(0);
  transition: filter 0.3s;
}

.lazy-load-image-background.blur > img {
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 1;
  transition: opacity 0.3s;
}

@-webkit-keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}
.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  /* Enable animation */
  background-color: var(--base-color);
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
  content: " ";
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color));
  transform: translateX(-100%);
  -webkit-animation-name: react-loading-skeleton;
          animation-name: react-loading-skeleton;
  -webkit-animation-direction: var(--animation-direction);
          animation-direction: var(--animation-direction);
  -webkit-animation-duration: var(--animation-duration);
          animation-duration: var(--animation-duration);
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.Toastify__toast {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  padding: 1.5rem;
  border-radius: 8px;
}
.Toastify__toast-body {
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 0.875rem;
  font-family: var(--bs-body-font-family) !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -8px;
  position: absolute;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #e8e8e8;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #fff;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #e8e8e8;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  display: block;
  width: 12px;
  height: 12px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23001927' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E ");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 8px 6px;
}

button {
  outline: none !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: var(--bs-font-sans-serif);
  font-size: 0.8rem;
  background-color: #fff;
  color: #737377;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  margin-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  margin-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  margin-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  margin-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-top-left-radius: 0.5rem;
  padding-top: 8px;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.5rem;
}
.react-datepicker__header__dropdown {
  margin-top: 8px;
  margin-bottom: 8px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 10px;
  top: 15px;
  border-right-color: #abadae;
}
.react-datepicker__navigation--previous:hover {
  border-right-color: #919495;
}
.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #c5c6c7;
  cursor: default;
}
.react-datepicker__navigation--next {
  top: 15px;
  right: 10px;
  border-left-color: #abadae;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}
.react-datepicker__navigation--next:hover {
  border-left-color: #919495;
}
.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #c5c6c7;
  cursor: default;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #abadae;
}
.react-datepicker__navigation--years-previous:hover {
  border-top-color: #919495;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #abadae;
}
.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #919495;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #e8e8e8;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #fff;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #0b8659;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #0b8659;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #abadae;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #abadae;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.5rem;
  background-color: #fff;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #737377;
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.5rem;
  background-color: #0b8659;
  color: #fff;
}
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #096e49;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #abadae;
  pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
  background-color: #fff;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 50%;
  background-color: #0b8659;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #096e49;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: #0b8659;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #096e49;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  background-color: #0fb578;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #096e49;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(11, 134, 89, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #fff;
  color: #737377;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #abadae;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #0b8659;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #fff;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.5rem;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  line-height: 24px;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #919495;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  position: absolute;
  right: 0px;
  top: 8px;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #fff;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid #e8e8e8;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #abadae;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #919495;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #919495;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #0b8659;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #fff;
  border-top: 1px solid #e8e8e8;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}
.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}
.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #abadae;
}
.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #919495;
}
.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #c5c6c7;
  cursor: default;
}
.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #abadae;
}
.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #919495;
}
.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #c5c6c7;
  cursor: default;
}